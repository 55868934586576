import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import myImg from "../../Assets/Vistrit.jpeg";
import Tilt from "react-parallax-tilt";
import {
  AiFillGithub,
  AiOutlineTwitter,
  AiFillInstagram,
} from "react-icons/ai";
import { FaLinkedinIn } from "react-icons/fa";

function Home2() {
  return (
    <Container fluid className="home-about-section" id="about">
      <Container>
        <Row>
          <Col md={8} className="home-about-description">
          <h1 style={{ fontSize: "2.6em" }}>
              LET ME <span className="purple"> INTRODUCE </span> MYSELF
            </h1>
            <p className="home-about-body">
              My journey in the world of programming began with curiosity and quickly grew into a passion that continues to fuel me. Over the years, I've had the privilege of working with a wide range of technologies and building innovative solutions.
              <br />
              <br />
              I am fluent in developing with modern technologies like
              <i>
                <b className="purple"> Python, React.js, TypeScript, and Next.js.</b>
              </i>
              <br />
              <br />
              My areas of interest lie in creating cutting-edge
              <i>
                <b className="purple">AI-driven products</b> and diving deep into the realm of 
                <b className="purple"> Large Language Models (LLMs) </b>
              </i>
              like ChatGPT, Llama, Mixtral and Gemini.
              <br />
              <br />
              I love crafting full-stack solutions and have experience working with a range of frameworks and platforms. Whenever possible, I bring my expertise in
              <i>
                <b className="purple"> AI and machine learning </b> 
              </i>
              to create impactful and scalable systems.
              <br />
              <br />
              Leading projects like <b className="purple">Meetext</b> and co-founding <b className="purple">LIAA</b> has taught me the value of innovation and teamwork, and I'm always excited to explore new challenges in software development and AI technology.
            </p>

          </Col>
          <Col md={4} className="myAvtar">
            <Tilt>
              <img src={myImg} className="img-fluid" alt="avatar" />
            </Tilt>
          </Col>
        </Row>
        <Row>
          {/*
            <Col md={12} className="home-about-social">
            <h1>FIND ME ON</h1>
            <p>
              Feel free to <span className="purple">connect </span>with me
            </p>
            <ul className="home-about-social-links">
              <li className="social-icons">
                <a
                  href="https://github.com/VistritPandey"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <AiFillGithub />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://twitter.com/Viztrit"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <AiOutlineTwitter />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://www.linkedin.com/in/vistrit/"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <FaLinkedinIn />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://www.instagram.com/Viztrit"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour home-social-icons"
                >
                  <AiFillInstagram />
                </a>
              </li>
            </ul>
          </Col>
          */}
        </Row>
      </Container>
    </Container>
  );
}
export default Home2;
