import React from "react";
import Card from "react-bootstrap/Card";
import { ImPointRight } from "react-icons/im";

function AboutCard() {
  return (
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote mb-0">
          <p style={{ textAlign: "justify" }}>
            Hey there, my name is <span className="purple">Vistrit Pandey, </span>
             <span className="purple"> Chicago based Software Engineer. </span>
            <br />
            I am currently a <span className="purple"> Software/ AI Engineer </span> 
            at <span className="purple"> Blinkfire Analytics </span>
            <br />
            I did my <span className="purple"> BS in Computer Science</span> from <span className="purple"> Drexel University (2023) </span>
            <br />
            <br />
            Past Experiences:
          </p>
          <ul>
            <li className="about-activity">
              <ImPointRight /> <span className="purple"> Blinkfire Analytics | Software Engineer I (AI R&D)</span> (April 2024 - Present)
            </li>
            <li className="about-activity">
              <ImPointRight /> <span className="purple"> Meetext | Founder & Software Engineer</span> (March 2023 - March 2024)
            </li>
            <li className="about-activity">
              <ImPointRight /> <span className="purple"> Drexel University | Course Assistant</span> (Sept 2022 - Sept 2023)
            </li>
            <li className="about-activity">
              <ImPointRight /> <span className="purple"> The Unnamed Co | Web & Mobile App Developer (co-op)</span> (Aug 2021 - March 2022)
            </li>
          </ul>
        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default AboutCard;
