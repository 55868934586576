import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";
import Drexel from "../../Assets/Projects/Drexel.png";
import Robinhood from "../../Assets/Projects/robinhood.png";
import Eventrov from "../../Assets/Projects/Eventrov.png";
import Meetext from "../../Assets/Projects/Meetext.png";
import Corona from "../../Assets/Projects/corona.png";
import Liaa from "../../Assets/Projects/Liaa.png";

function Projects() {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          Some of my <strong className="purple">work </strong>
        </h1>
        <p style={{ color: "white" }}>
          Here are a few projects I recently worked on
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={Meetext}
              isBlog={false}
              title="Meetext"
              description="AI Meeting Summarizer/ Note taker, that summarizes your meetings in real time. It has over 15,000 users from over 100+ countries"
              ghLink=""
              demoLink="http://meetext.info"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={Liaa}
              isBlog={false}
              title="Liaa"
              description="An AI Medical Scribe for doctors. The tool was built for doctors, with high security and HIPAA compliance. MERGED WITH MEETEXT"
              ghLink=""
              demoLink="https://liaascribe.com"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={Eventrov}
              isBlog={false}
              title="Eventrov"
              description="An AI event planner, and social media platform for people in the event business to connect with (INACTIVE)"
              ghLink=""
              demoLink="http://eventrov.events"              
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={Drexel}
              isBlog={false}
              title="Drexel University Wesbite"
              description="Built Drexel University's website that hosts over 100,000 users per month"
              ghLink=""
              demoLink="http://drexel.edu"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={Robinhood}
              isBlog={false}
              title="Stock Market Prediction"
              description="Built a stock market tracker, and predictor, that helps me track stocks and send notifications periodically based on the events occuring for that company's stock"
              ghLink=""
              demoLink=""
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={Corona}
              isBlog={false}
              title="COVID Tracker"
              description="A COVID Tracker, built during the pandemic, to scrape real time data and show users COVID cases in a country. (NOT MAINTAINED)"
              ghLink=""
              demoLink=""              
            />
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Projects;
